import { defineStore } from "pinia";
import { ref } from "vue";
import { labBookingName as labBookingNameConstant } from "~/constants/preventiveCare";
import { useGlobalStore } from "~/store/global";
import { useLabDayStore } from "~/store/labDay";
import type { BookPreventiveCare } from "~/types/booking-api/BookPreventiveCare";
import { PreventiveCareType } from "~/types/booking-api/PreventiveCareType";
import type { SchedulerService } from "~/types/SchedulerService";
import type { Department } from "~/types/strapi/api/department/content-types/department/department";
import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Service } from "~/types/strapi/api/service/content-types/service/service";
import { isGeneraliService } from "~/utils/preventiveCare/isGeneraliService";
import { isUniqaVitalcheckAddonService } from "~/utils/preventiveCare/isUniqaVitalcheckAddonService";
import { isUniqaVitalcheckService } from "~/utils/preventiveCare/isUniqaVitalcheckService";
import { isWienerStaedtischeService } from "~/utils/preventiveCare/isWienerStaedtischeService";

type DepartmentInfo = Pick<
  Department["attributes"],
  "title" | "reimbursement" | "isAMed" | "mobimed_patient_category"
> & {
  discount: number;
};
type HealthProfessionalInfo = Pick<
  HealthProfessional["attributes"],
  "bookingID" | "hasMembershipBenefits" | "fullName"
>;
type ServiceInfo = Pick<Service["attributes"], "serviceId" | "name" | "description" | "laboratoryServiceId">;

export const useBookingStore = defineStore("booking", () => {
  const globalStore = useGlobalStore();
  const locationStore = useLocationsStore();
  const department = ref<DepartmentInfo | undefined>();
  const hasMembershipBenefitsSelected = ref<boolean>(true);
  const healthProfessional = ref<HealthProfessionalInfo | undefined>(undefined);
  const activeLocation = ref<StoredLocation | undefined>(undefined);
  const location = ref<StoredLocation | null>(null);
  const schedulerService = ref<SchedulerService | null>(null);
  const selectedHealthProfessionalDate = ref<string | null>(null);
  const service = ref<ServiceInfo | undefined>();
  const wantToBuyMembership = ref<boolean>(false);

  //======
  // preventive care
  const labDayStore = useLabDayStore();
  const bookedPreventiveCare = ref<BookPreventiveCare | null>(null);
  const isPreventiveCheckupWithinAYear = ref<boolean>(false);
  const preventiveCareType = ref<PreventiveCareType>(PreventiveCareType.HAELSI_PREVENTIVE_CARE);
  const selectedLabDate = ref<string | null>(null);
  const selectedUltrasoundHealthProfessional = ref<string | undefined>();
  //======

  function getActiveLocationHealthProfessionalIds(): Set<number> | undefined {
    if (activeLocation.value === undefined) {
      return undefined;
    }

    return locationStore.getHealthProfessionalIdsBySlug(activeLocation.value.attributes.slug);
  }

  async function setActiveLocationBySlug(slug: string): Promise<void> {
    if (slug === "") {
      activeLocation.value = undefined;
    } else {
      activeLocation.value = await locationStore.getLocationBySlug(slug);
    }
  }

  function setHealthProfessionalDate(date: string | null): void {
    selectedHealthProfessionalDate.value = date;
  }

  function setHealthProfessional(currentHealthProfessional?: HealthProfessional): void {
    if (currentHealthProfessional === undefined) {
      return;
    }

    healthProfessional.value = (({ bookingID, fullName, hasMembershipBenefits }) => ({
      bookingID,
      fullName,
      hasMembershipBenefits,
    }))(currentHealthProfessional.attributes);
  }

  function setDepartment(currentDepartment?: Department): void {
    if (isNullOrUndefined(currentDepartment)) {
      return;
    }

    department.value = (({ title, reimbursement, isAMed, mobimed_patient_category }) => ({
      discount: getDiscountForDepartment(title),
      isAMed,
      mobimed_patient_category,
      reimbursement,
      title,
    }))(currentDepartment.attributes);
  }

  function setService(currentService?: Service): void {
    if (currentService === undefined) {
      return;
    }

    service.value = (({ description, laboratoryServiceId, name, serviceId }) => ({
      description,
      laboratoryServiceId,
      name,
      serviceId,
    }))(currentService.attributes);
  }

  //======
  // preventive care
  function setLabDate(date: string | null): void {
    selectedLabDate.value = date;
  }
  //======
  const canHaveBookNowPayLater = computed(() => globalStore.isActivePromotion === false && servicePrice.value !== 0);
  const hasAppointmentDateSelected = computed(() => selectedHealthProfessionalDate.value !== null);
  const hasHealthProfessionalBenefits = computed(() => healthProfessional.value?.hasMembershipBenefits ?? true);
  const isAMed = computed(() => department.value?.isAMed ?? false);
  const locationText = computed(
    () =>
      `${location.value?.attributes.address?.street_with_number}, ${location.value?.attributes.address?.postal_code} ${location.value?.attributes.address?.city}`,
  );
  const serviceName = computed(() => {
    if (service.value === undefined) {
      return "";
    }

    return getAppointmentNameFromService(service.value.name);
  });
  const servicePrice = computed(() => {
    if (isUniqaVitalcheckAddonService(service.value?.serviceId)) {
      return 0;
    }

    return schedulerService.value?.price ? parseFloat(schedulerService.value.price) : undefined;
  });
  // the discount is the percentage value of discount which will be given for this department
  const discount = computed(() => getDiscountForDepartment(department.value?.title));
  const reducedPrice = computed(() =>
    servicePrice.value && discount.value
      ? roundToPrecision(servicePrice.value * (1 - discount.value / 100), 2)
      : undefined,
  );
  const savings = computed(() =>
    servicePrice.value && reducedPrice.value ? roundToPrecision(servicePrice.value - reducedPrice.value, 2) : undefined,
  );
  const hasServiceBenefits = computed(() => isAMed.value === false && hasHealthProfessionalBenefits.value === true);

  //======
  // preventive care
  const hasPreventiveCareDatesSelected = computed(
    () => selectedLabDate.value !== null && selectedHealthProfessionalDate.value !== null,
  );
  const isGeneraliServiceId = computed(() => isGeneraliService(service.value?.serviceId));
  const isUniqaVitalcheckServiceId = computed(() => isUniqaVitalcheckService(service.value?.serviceId));
  const isWienerStaedtischeServiceId = computed(() => isWienerStaedtischeService(service.value?.serviceId));
  const labBookingName = computed(() =>
    isUniqaVitalcheckServiceId.value ? `${labBookingNameConstant} inkl. Ultraschall` : labBookingNameConstant,
  );
  const labServiceId = computed(() => {
    const externalLabServiceId = 5871;
    if (labDayStore.isValidLabDay) {
      return externalLabServiceId;
    }

    const labServiceId = service.value?.laboratoryServiceId;

    return labServiceId ? parseInt(labServiceId.toString(), 10) : undefined;
  });
  //======

  watch(hasMembershipBenefitsSelected, (currentMembershipBenefits) => {
    if (canHaveBookNowPayLater.value === false) {
      wantToBuyMembership.value = false;
      return;
    }

    wantToBuyMembership.value = currentMembershipBenefits === false;
  });

  function resetDates(): void {
    selectedHealthProfessionalDate.value = null;
    selectedLabDate.value = null;
  }

  function resetSelection(): void {
    resetDates();
    healthProfessional.value = undefined;
    isPreventiveCheckupWithinAYear.value = false;
  }

  function $reset(): void {
    resetSelection();
    bookedPreventiveCare.value = null;
    department.value = undefined;
    hasMembershipBenefitsSelected.value = true;
    location.value = null;
    activeLocation.value = undefined;
    preventiveCareType.value = PreventiveCareType.HAELSI_PREVENTIVE_CARE;
    schedulerService.value = null;
    selectedUltrasoundHealthProfessional.value = undefined;
    service.value = undefined;
    wantToBuyMembership.value = false;
  }

  return {
    $reset,
    activeLocation,
    bookedPreventiveCare,
    canHaveBookNowPayLater,
    department,
    discount,
    getActiveLocationHealthProfessionalIds,
    hasAppointmentDateSelected,
    hasHealthProfessionalBenefits,
    hasMembershipBenefitsSelected,
    hasPreventiveCareDatesSelected,
    hasServiceBenefits,
    healthProfessional,
    isAMed,
    isGeneraliServiceId,
    isPreventiveCheckupWithinAYear,
    isUniqaVitalcheckServiceId,
    isWienerStaedtischeServiceId,
    labBookingName,
    labServiceId,
    location,
    locationText,
    preventiveCareType,
    reducedPrice,
    resetDates,
    resetSelection,
    savings,
    schedulerService,
    selectedHealthProfessionalDate,
    selectedLabDate,
    selectedUltrasoundHealthProfessional,
    service,
    serviceName,
    servicePrice,
    setActiveLocationBySlug,
    setDepartment,
    setHealthProfessional,
    setHealthProfessionalDate,
    setLabDate,
    setService,
    wantToBuyMembership,
  };
});
